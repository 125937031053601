import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import parse from "html-react-parser"

const Clients = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: wpPage(isFrontPage: { eq: true }) {
        Homepage {
          clients {
            altText
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  `)

  const CLI = data.Homepage.clients

  return (
    <section id="clients" className="each-section clients">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12">
            <h2>Our clients</h2>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-12">
            <div className="client_wrapper">
              {CLI.map((client, index) => {
                const gatImage = getImage(client.localFile.childImageSharp)
                const oImage = client.localFile.publicURL

                return (
                  <div key={index} className="each_client">
                    <a
                      href={client.title}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={client.altText}
                    >
                      {client.altText}
                    </a>
                    {gatImage ? (
                      <GatsbyImage image={gatImage} alt={client.altText} />
                    ) : (
                      <img
                        src={oImage}
                        alt={client.altText}
                        width={"200px"}
                        height={"auto"}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients
