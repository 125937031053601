import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Lead from "../components/sections/lead"
import Approaches from "../components/sections/approaches"
import Clients from "../components/sections/clients"
import Story from "../components/sections/story"

export default function Home({ data }) {
  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      <Lead />
      <Approaches />
      <Clients />
      <Story />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      title
      seo {
        metaDesc
        title
      }
    }
  }
`
