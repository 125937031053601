import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Story = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: wpPage(isFrontPage: { eq: true }) {
        Homepage {
          extraContent {
            leftOrRightImage
            columnContent
            columnImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    sizes: "100"
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const SecondC = data.Homepage.extraContent

  return (
    <section id="story" className="each-section story">
      {SecondC.map((content, index) => {
        const Image = getImage(content.columnImage.localFile.childImageSharp)

        return (
          <div
            key={index}
            className={
              "extra-content two-column imagepos-" + content.leftOrRightImage
            }
          >
            <div className="each-column">
              <div className="content">{parse(content.columnContent)}</div>
            </div>
            <div className="each-column image">
              <GatsbyImage image={Image} alt={"Direct Design"} />
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default Story
