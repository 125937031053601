import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import parse from "html-react-parser"

const Approaches = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: wpPage(isFrontPage: { eq: true }) {
        Homepage {
          approaches {
            approacheTitle
            approacheImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    width: 1000
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const Appr = data.Homepage.approaches

  return (
    <section id="approach" className="each-section approaches">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12">
            <ul className="slides">
              {Appr.map((approache, index) => {
                const Image = getImage(
                  approache.approacheImage.localFile.childImageSharp
                )
                return (
                  <li className="slide" key={index}>
                    <GatsbyImage image={Image} alt={approache.approacheTitle} />
                    <span className="title">{approache.approacheTitle}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Approaches
