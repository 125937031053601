import React, { useState, useEffect } from "react"
import { useLottie } from "lottie-react"
import LeadLogo from "/static/bodymdata.json"

export default function AnimateLogo() {
  const [count, setCount] = useState(false)
  const options = {
    animationData: LeadLogo,
    rederer: "svg",
    loop: false,
    autoplay: true,
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCount(true)
    }, 4000)

    return () => clearTimeout(timeout)
  }, [count])

  const { View } = useLottie(options)

  return (
    <div id="anim" className={count ? "show" : "hidden"}>
      <h1>
        <span>Bold</span>
        <span>Creative</span>
        <span>Thinking</span>
      </h1>
      {View}
    </div>
  )
}
